<template>

  <div>

      

      <div class="container">

              <!-- <div class="smiley"><img src="@/assets/popeye.jpg" alt="Offshore SMS"></div> -->

              
              <p class="dash-heading is-size-4 has-text-centered">{{ msg }}</p>

              <div class="dashtile-wrapper">

                <div v-if="!choiceMade">
                  <h3 class="has-text-centered">You have joined a growing community of safety conscious commercial vessel owners and crew.</h3>
                  <p>&nbsp;</p>
                </div>

                <p class="has-text-centered">What would you like to do next?</p>
                <p>&nbsp;</p>


                    <div @click="showDetails('sms')" class="dashtile vessels" :class="[showInfo == 'sms' ? 'active' : '']">
                      <div class="content-wrap">
                        <font-awesome-icon icon="ship" />
                        <p class="title">Create a<br>vessel SMS?</p>
                      </div>
                    </div>
                  

                  
                    <div @click="showDetails('free')" class="dashtile crew" :class="[showInfo == 'free' ? 'active' : '']">
                      <div class="content-wrap">
                        <font-awesome-icon icon="user-alt" />
                        <p class="title">Continue as<br>a Free user</p>
                      </div>
                    </div>

                    <div class="clear"><p>&nbsp;</p></div>
                  
                    
                    <!-- <div v-if="showInfo == 'sms'" class="notification has-text-centered">
                      <p class="is-size-5">Great! Setting up an SMS is Easy.</p>
                      <p>Simply choose how many vessels you have and start creating an SMS in a few clicks. Click the button below to get started...</p>
                    </div>

                    <div v-if="showInfo == 'free'" class="notification has-text-centered">
                      <p class="is-size-5">No problem. You can set up an SMS any time!</p>
                      <p>As a free user other vessel owners can connect with you, plus you can add 1 Free vessel at any time from your vessels page.</p>
                    </div> -->

                    <div class="has-text-centered">

                      <router-link class="button is-primary" v-if="showInfo == 'sms'" to="pricing">
                        View Subscription Options
                      </router-link>

                      <router-link class="button is-info" v-if="showInfo == 'free'" to="my-dashboard">
                        Continue to My Dashboard
                      </router-link>

                    </div>


                  
                  <div class="clear"></div>
                  

              </div>




      </div>

      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':choiceMade}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          
            <header class="modal-card-head">
              <p v-if="showInfo == 'sms'" class="modal-card-title">Create an SMS</p>
              <p v-if="showInfo == 'free'" class="modal-card-title">Continue as Free user</p>
              <!-- <button class="delete" aria-label="hasSeenNotice" @click.prevent="hasSeenNotice"></button> -->
            </header>
            <section class="modal-card-body">       

              <div v-if="showInfo == 'sms'" class="has-text-centered">
                <p class="is-size-5">Great! Setting up an SMS is Easy.</p>
                <p>Simply choose how many vessels you have and start creating an SMS in a few clicks. Click the button below to get started...</p>
              </div>

              <div v-if="showInfo == 'free'" class="has-text-centered">
                <p class="is-size-5">No problem. You can set up an SMS any time!</p>
                <p>As a Free user you can manage your own personal profile, add qualifications and more, plus other vessel owners can connect with you and add you to their vessels as crew.</p>
              </div>

            </section>
            <footer class="modal-card-foot has-text-centered">
              <!-- <router-link class="button is-primary" to="my-vessels">Go to My Vessels</router-link> -->
              <button v-if="showInfo == 'free'" class="button is-info" @click="nextPage('my-dashboard')">View Dashboard</button>
              <!-- <button v-if="showInfo == 'sms'" class="button is-primary" @click="nextPage('new-subscription')">Get an SMS</button> -->
              <button v-if="showInfo == 'sms'" class="button is-primary" @click="nextPage('sms-signup')">Get an SMS</button>
              <button class="button" @click="close">Back</button>
            </footer>        

        </div>
      </div>

  </div>

</template>



<script>

  //import { db } from '../main.js';
  // import firebase from 'firebase/app';
  // import 'firebase/auth'; 
  //import 'firebase/firestore';
  //import 'firebase/functions';
  // import NProgress from 'nprogress';
  //import { store } from '../store/store';

  // import MobileNav from '../components/MobileNav.vue';

  // const StickyFooter = () => import('../reusables/StickyFooter.vue');



  export default {
    name: 'Welcome',
    components: {
      // StickyFooter,
    },
    computed: {
      isUserAdmin() {
        return this.$store.getters.getIsUserAdmin;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },

    },

    async mounted() {
      // var self = this;
      this.$store.dispatch('updateUserState');
         
    },
    data: function() {
      return {
        msg: 'Welcome to Offshore SMS!',
        firebaseid: '',
        firebaseemail: '',
        firebaserole: '',
        firebasedisplayname: '',
        showInfo: '',
        choiceMade: false,
      }        
    },
    methods: {
      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseemail = user.email;
          //this.firebasedisplayname = user.displayName;
          this.firebasedisplayname = this.userData.username;
          this.firebaseid = user.uid;
          this.$store.dispatch('updateUserState');
        }
      },

      close: function() {
        this.choiceMade = false;
      },

      showDetails: function(type) {
        this.choiceMade = true;
        this.showInfo = type;
      },

      nextPage: function(route) {
        this.$router.replace(route);
      }



    }
  }


</script>

<style lang="scss" scoped>

  .tile {
    article {
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center !important;
      a, a:visited {
        text-decoration: none !important;
        width: 100%;
        height: 100%;
        padding: 2rem 0;
        p {
          font-size: 1.2rem;
          line-height: 1.5rem;
          text-align: center;
        }
      }
    }
  }

  .modal-card-foot, .modal-card-head {
    justify-content: center;
    text-align: center;
  }


.parent-element {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.child-element {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

  .dashtile-wrapper {
    .dashtile {
      width: 46%;
      float: left;
      background: red;
      margin: 0 2% 18px;
      text-align: center;
      height: 200px;
      color: #fff;
      border-radius: 5px;
      transform-style: preserve-3d;
      transition: 0.3s;
      cursor: pointer;
      transition: 0.3s;     
      &.active {
        transform: scale(1.05);
      }
      // &:hover {
      //  transform: scale(1.05);
      // }
      p {
        color: #ffffff;
        margin-bottom: 0;
        &.count {
          font-size: 22px;
        }
      }
      &.alerts {
        background: #ffcd2e;
      }
      &.profile {
        background: #d4d4d4;
        p, .content-wrap {
          color: #444;
        }
      }
      &.vessels {
        background: #00d1b2;
      }
      &.crew {
        background: #209cee;
      }
      &.jobs {
        background: #ffdd57;
      }
      .content-wrap {       
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        svg {
          font-size: 20px;
          margin-bottom: 4px;
        }
      }
    }
    h3 {
      max-width: 400px;
      margin: 0 auto;
    }
  }
  

  .field.has-addons {
    margin: 1rem 0;
  }

  .dash-heading {
    color: #245e8b;
    margin: 0 0 1rem;
  }
  .dashboard {
    &.section {
      margin-bottom: 0;
    }
  }
  

  .upload-wrapper {
    label[for="firstImage"] {
      text-align: center;
      display: block;
      figure {
        display: inline-block;
        margin-right: 8px;
      }
      span {
        display: inline-block;
        vertical-align: super;
      }
    }
  }

  .smiley {
    text-align: center;
  }



</style>
